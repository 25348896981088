const ENTERPRISE_BASE_URL = process.env.GATSBY_ENTERPRISE_APP_URL;

export const getB2bLinks = (slug: string) => {
  const signInUrl = `https://my.parsleyhealth.com/login`;
  const signUpUrl = `${ENTERPRISE_BASE_URL}/welcome/${slug}`;

  return {
    signInUrl,
    signUpUrl
  };
};
