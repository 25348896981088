import React from 'react';
import clsx from 'clsx';
import { SanityBasicImage } from '../../../../../graphql-types';
import { NavContainer } from '../nav-container/nav-container';
import { Button } from '../../../atoms/button';
import { Link } from '../../../atoms/link';
import { Image } from '../../../atoms/image/image';
import { ImageType } from '../../../atoms/image/types';
import styles from './b2b-nav.module.css';
import { getB2bLinks } from '../../../../lib/b2b-utils';

type B2BMainNavProps = {
  slug: string;
  partnerLogo?: SanityBasicImage;
  companyLogo: SanityBasicImage;
};

const B2BMainNav: React.FC<B2BMainNavProps> = ({ slug, partnerLogo }) => {
  const { signInUrl, signUpUrl } = getB2bLinks(slug);

  // TODO - add an option to hide the nav buttons to the studio schema

  return (
    <NavContainer alwaysShow>
      <div className="inline-flex-center">
        {partnerLogo && (
          <Link
            destination={signUpUrl}
            className={styles.b2bNavPartnerLogoWrapper}
          >
            <Image {...partnerLogo} type={ImageType.SVG} roundBorder={false} />
          </Link>
        )}
      </div>
      <div className={styles.b2bCenterButtons}>
        <Link
          color="darkGreen"
          className={clsx(styles.b2bBtnSignIn, 'mr-xxs')}
          destination={signInUrl}
          variant="u1a"
          aria-label="sign in link"
        >
          Sign In
        </Link>
        <Button
          color="darkGreen"
          variant="filled"
          size="small"
          trackingLabel="sign up button"
          destination={signUpUrl}
        >
          Sign Up
        </Button>
      </div>
    </NavContainer>
  );
};

export default B2BMainNav;
